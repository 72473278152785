.chat-ui {
  max-width: 860px;
  margin: 0 auto 40px auto;
}
@media (min-width: 1490px) {
  .chat-ui {
    max-width: 900px;
  }
}
.chat-form .content {
  padding: 20px;
}
.chat-form {
  background: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 8px;
}
@media (max-width: 1445px) {
  .chat-form {
    margin: 0 40px;
  }
}
.chat-form .ant-input {
  border-radius: 5px 0 0 5px;
}

.chat-loader {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 45%;
  z-index: 100000;
}
.chat-ui-input {
  margin-bottom: 5px;
  font-size: 16px !important;
}
.chat-ui-btn {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
